import router from './router/index';
import {getToken, removeToken} from './utils/auth'
import {checkToken} from './api/login'

const whiteList = ['/login', '/404', '/403'];
router.beforeEach(async (to, from, next) => {
  let _token = getToken();
  if (_token) {
    checkToken(_token).then(res => {
      if (res) {
        next()
      } else {
        this.$message.error("登录超时,请重新登录！");
        removeToken();
        next('/login')
      }
    }).catch(res => {
      this.$message.error("登录超时,请重新登录！");
      removeToken();
      next('/login')
    });
    if (whiteList.indexOf(to.path) !== -1) {
      next('/cashiers/cashier')
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login')
    }
  }
})

