import {getToken} from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    roles: [],
  },

  mutations: {
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
  },

  actions: {
    // 设置按钮权限
    setRoles({commit}, roles) {
      commit('SET_ROLES', roles)
    },
  }
}


export default user
