import axios from 'axios'
// import {createSearchParams} from 'utils/main'
import {Base_url} from '../config/index'
import {resizeParams} from "../utils/http";

//登录
function login(loginName, password) {
  return new Promise(resolve => {
    axios({
      method: 'post',
      url: Base_url + '/admin/login',
      params: {
        loginName: loginName,
        password: password
      }
    }).then((res) => {
      resolve(res.data)
    })
  })
}

// 检查token是否过期
function checkToken(token = {}) {
  let data = {
    token: token
  }
  let _params = resizeParams(data)
  return new Promise((resolve, reject) => {
    axios.get(Base_url + '/sw/vrifyToken', {
      params: _params
    }).then(res => {
      resolve(res.data)
    }).catch(reject)
  })
}

export {
  login,
  checkToken
}
