import Vue from 'vue'
import App from './App.vue'
import './plugins/element'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './filters/formatter';
import './assets/css/reset.css'
import '../static/css/main.css'
import '../static/css/iconfont/iconfont.css'
import '../static/css/iconfont/iconfont'
import './assets/css/emoji-sprite.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import './assets/infont/iconfont'
import './assets/infont/iconfont.css'
import './assets/infont-a/iconfont'
import './assets/infont-a/iconfont.css'
import 'default-passive-events'
import vUploadVideo from './components/common/uploadVideo/index'
import {checkPermission } from './utils/main';
import { get, post,fileUpload } from "./utils/http";
import './permisson'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import VueAMap from 'vue-amap'
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$video = Video
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$fileUpload = fileUpload;

Vue.prototype.checkPermission = checkPermission;

import VueClipboard from 'vue-clipboard2'
import DatePicker from '../src/components/datePicker/index';
Vue.use(DatePicker)
Vue.component('vUploadVideo',vUploadVideo);
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(VueAMap)

VueAMap.initAMapApiLoader({
    key: 'a0a67deb466cfd76747c357f838b2a9b',
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.MarkerClusterer'],
    // 默认高德 sdk 版本为 1.4.4
    v: '1.4.15'
})
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
}).$mount('#app');

